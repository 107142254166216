@charset "UTF-8";
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Rajdhani", sans-serif;
}

button,
[role=button] {
  cursor: pointer;
}

body {
  background-color: #111827;
  color: #f3f4f6;
  min-height: 100vh;
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.app-container {
  display: flex;
  min-height: 100vh;
}

.main-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 16px;
}

.input-primary {
  background-color: #1f2937;
  border: 1px solid #374151;
  border-radius: 8px;
  color: #f3f4f6;
  padding: 12px;
  font-size: 16px;
  width: 100%;
  transition: border-color 0.2s ease;
}
.input-primary:focus {
  outline: none;
  border-color: #6366f1;
}

.cta-1 {
  background-color: #6366f1;
  color: #f3f4f6;
  border: none;
  border-radius: 8px;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.2s ease, transform 0.1s ease;
}
.cta-1:hover {
  background-color: #4f46e5;
}
.cta-1:active {
  transform: scale(0.98);
}

.modal-backdrop {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal {
  background-color: #1f2937;
  border: 1px solid #374151;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  padding: 20px;
  max-width: 500px;
  width: 90%;
}
.modal h3 {
  color: #f3f4f6;
  font-size: 20px;
  margin-bottom: 16px;
}

.loginForm {
  background-color: #1f2937;
  border: 1px solid #374151;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  width: 100%;
  max-width: 400px;
  margin: 60px auto;
  padding: 24px;
}
.loginForm h2 {
  color: #f3f4f6;
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 20px;
}
.loginForm form {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.loginForm__usernameContainer, .loginForm__passwordContainer {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.loginForm label {
  color: #9ca3af;
  font-size: 14px;
  font-weight: 500;
}
.loginForm input {
  background-color: #1f2937;
  border: 1px solid #374151;
  border-radius: 8px;
  color: #f3f4f6;
  padding: 12px;
  font-size: 16px;
  width: 100%;
  transition: border-color 0.2s ease;
}
.loginForm input:focus {
  outline: none;
  border-color: #6366f1;
}
.loginForm button {
  background-color: #6366f1;
  color: #f3f4f6;
  border: none;
  border-radius: 8px;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.2s ease, transform 0.1s ease;
  margin-top: 12px;
}
.loginForm button:hover {
  background-color: #4f46e5;
}
.loginForm button:active {
  transform: scale(0.98);
}
.loginForm p {
  color: #FF5252;
  font-size: 14px;
  margin-top: 12px;
  text-align: center;
}

.header-wrapper {
  width: 100%;
  position: relative;
  margin-bottom: 20px;
}

.header {
  width: 100%;
  height: 160px;
  background: linear-gradient(135deg, #1f2937 0%, #111827 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
}
.header::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: radial-gradient(circle at 25px 25px, #374151 1px, transparent 0);
  background-size: 50px 50px;
  opacity: 0.1;
}
.header #logoName {
  font-size: 48px;
  font-weight: 700;
  color: #f3f4f6;
  letter-spacing: 2px;
  position: relative;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}
.header #logoName::after {
  content: "";
  position: absolute;
  bottom: -8px;
  left: 50%;
  transform: translateX(-50%);
  width: 40px;
  height: 3px;
  background-color: #6366f1;
  border-radius: 2px;
}

.header-top {
  width: 100%;
  height: 40px;
  background-color: #374151;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 20px;
}
.header-top .logout-button {
  padding: 4px 12px;
  background-color: transparent;
  border: 1px solid #FF5252;
  color: #FF5252;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s ease;
}
.header-top .logout-button:hover {
  background-color: #FF5252;
  color: white;
}

@media (max-width: 768px) {
  .header {
    height: 120px;
  }
  .header #logoName {
    font-size: 36px;
  }
}
.nav {
  width: 100%;
  height: 60px;
  background-color: #1f2937;
  margin-bottom: 20px;
  border-bottom: 1px solid #374151;
  position: relative;
}
.nav .nav-logo {
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 20px;
  font-weight: 700;
  color: #f3f4f6;
}
.nav .nav-actions {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  gap: 12px;
}
.nav .nav-actions .nav-logout {
  padding: 8px 12px;
  background-color: transparent;
  border: 1px solid #FF5252;
  color: #FF5252;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s ease;
}
.nav .nav-actions .nav-logout:hover {
  background-color: #FF5252;
  color: white;
}
.nav a {
  text-decoration: none;
  color: #9ca3af;
  background-color: transparent;
  transition: color 0.2s ease;
  font-weight: 500;
  font-size: 16px;
}
.nav a:hover {
  color: #f3f4f6;
}
.nav a.active {
  color: #6366f1;
  font-weight: 600;
}
.nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  height: 100%;
  display: flex;
  justify-content: center;
  gap: 48px;
  background-color: transparent;
  max-width: 800px;
  margin: 0 auto;
}
.nav .dropdown {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 20px;
  background-color: transparent;
  min-width: 120px;
  justify-content: center;
}
.nav .dropdown > a {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  white-space: nowrap;
}
.nav .dropdown > a::after {
  content: "▼";
  font-size: 8px;
  margin-left: 4px;
  opacity: 0.5;
  position: relative;
  top: 1px;
}
.nav .dropdown > a.active::after {
  opacity: 1;
}
.nav .dropdown:hover > a {
  color: #f3f4f6;
}
.nav .dropdown-content {
  display: none;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  min-width: 200px;
  background-color: #1f2937;
  border: 1px solid #374151;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  overflow: hidden;
  z-index: 1000;
}
.nav .dropdown-content a {
  padding: 12px 16px;
  display: block;
  background-color: transparent;
  text-align: left;
}
.nav .dropdown-content a:hover {
  background-color: #374151;
}
.nav .dropdown-content a.active {
  background-color: rgba(99, 102, 241, 0.1);
}
.nav li:hover .dropdown-content {
  display: block;
  animation: fadeIn 0.2s ease;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translate(-50%, -10px);
  }
  to {
    opacity: 1;
    transform: translate(-50%, 0);
  }
}
.nav::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 60px;
  background: linear-gradient(to bottom, rgba(31, 41, 55, 0.5), rgba(17, 24, 39, 0.5));
  pointer-events: none;
}

.programs-container h2 {
  margin-top: 1rem;
  margin-bottom: 2rem;
}
.programs-container a {
  text-decoration: none;
  color: black;
}

.workouts-container h2 {
  margin-top: 1rem;
  margin-bottom: 2rem;
}
.workouts-container a {
  text-decoration: none;
  color: black;
}
.workouts-container .workouts-container__workout {
  border: 1px solid #D1D5DB;
  background-color: #373737;
  margin-bottom: 1rem;
  text-decoration: none;
  font-size: large;
  padding: 0.5rem;
}
.workouts-container .workouts-container__workout:hover {
  background-color: #5d5d5d;
}

.create-program-container {
  margin: 20px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  max-width: 500px;
  margin: 20px auto;
}
.create-program-container h2 {
  margin-bottom: 20px;
}
.create-program-container .create-program-container__name {
  margin-bottom: 15px;
}
.create-program-container label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}
.create-program-container input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #2E2E2E;
}
.create-program-container button {
  background-color: rgb(0, 123, 255);
  border-radius: 4px;
  padding: 10px;
  margin-top: 20px;
  color: white;
  font-size: 16px;
  text-align: center;
  border: none;
  cursor: pointer;
  width: 100%;
}
.create-program-container button:hover {
  background-color: rgb(43, 145, 255);
}

.create-program-container select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #2E2E2E;
}

.programs-container {
  margin: 20px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #171717;
  max-width: 500px;
  margin: 20px auto;
}

.error-message {
  color: red;
  margin-top: 10px;
  margin-bottom: 10px;
}

.programs-container h2 {
  margin-bottom: 20px;
}

.programs-container__program {
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 4px;
  transition: background-color 0.3s ease;
  background-color: #2E2E2E;
}

.programs-container__program:hover {
  background-color: #373737;
}

.programs-container__program a {
  text-decoration: none;
  color: #333;
}

.programs-container__program a:hover {
  text-decoration: underline;
}

.add-exercises-form {
  background-color: #1f2937;
  border: 1px solid #374151;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  max-width: 500px;
  display: flex;
  flex-direction: column;
  margin: 20px auto;
  padding: 20px;
}
.add-exercises-form input, .add-exercises-form select {
  background-color: #1f2937;
  border: 1px solid #374151;
  border-radius: 8px;
  color: #f3f4f6;
  padding: 12px;
  font-size: 16px;
  width: 100%;
  transition: border-color 0.2s ease;
  margin-bottom: 12px;
}
.add-exercises-form input:focus, .add-exercises-form select:focus {
  outline: none;
  border-color: #6366f1;
}
.add-exercises-form label {
  display: block;
  margin-bottom: 8px;
  font-weight: 600;
  color: #f3f4f6;
}
.add-exercises-form .add-exercises-form__input-container,
.add-exercises-form .add-exercises-form__select-container {
  display: flex;
  flex-direction: column;
  margin-top: 16px;
}
.add-exercises-form .add-exercises-form__select-container {
  margin-bottom: 16px;
}
.add-exercises-form button {
  background-color: #6366f1;
  color: #f3f4f6;
  border: none;
  border-radius: 8px;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.2s ease, transform 0.1s ease;
  margin-top: 16px;
}
.add-exercises-form button:hover {
  background-color: #4f46e5;
}
.add-exercises-form button:active {
  transform: scale(0.98);
}

.exercise-container {
  background-color: #1f2937;
  border: 1px solid #374151;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  max-width: 500px;
  display: flex;
  flex-direction: column;
  margin: 20px auto;
  padding: 20px;
}
.exercise-container h2 {
  color: #f3f4f6;
  font-size: 24px;
  margin-bottom: 20px;
  font-weight: 700;
}
.exercise-container h3 {
  color: #6366f1;
  font-size: 20px;
  margin-top: 20px;
  margin-bottom: 12px;
  font-weight: 600;
  padding-bottom: 4px;
  border-bottom: 2px solid #6366f1;
}
.exercise-container ul {
  list-style: none;
  padding: 0;
}
.exercise-container ul li {
  color: #f3f4f6;
  padding: 12px 8px;
  font-size: 16px;
}
.exercise-container ul li:not(:last-child) {
  border-bottom: 1px solid #374151;
}
.exercise-container ul li:hover {
  background-color: #374151;
  border-radius: 4px;
}

.program-editor {
  display: flex;
  height: calc(100vh - 72px);
  gap: 20px;
  padding: 20px;
  background-color: #111827;
  overflow: hidden;
}

.program-editor__sidebar {
  width: 300px;
  background-color: #1f2937;
  border: 1px solid #374151;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  padding: 16px;
  border-radius: 12px;
}

.program-editor__sidebar__search {
  position: relative;
  margin-bottom: 16px;
}

.program-editor__sidebar__search input {
  background-color: #1f2937;
  border: 1px solid #374151;
  border-radius: 8px;
  color: #f3f4f6;
  padding: 12px;
  font-size: 16px;
  width: 100%;
  transition: border-color 0.2s ease;
  padding-left: 30px;
  background-color: #111827;
}
.program-editor__sidebar__search input:focus {
  outline: none;
  border-color: #6366f1;
}

.program-editor__sidebar__search input:focus {
  border-color: #6366f1;
}

.program-editor__sidebar__search .search-icon {
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  color: #9ca3af;
}

.program-editor__sidebar__list {
  overflow-y: auto;
  max-height: calc(100vh - 200px);
}

.program-editor__sidebar__list button {
  width: 100%;
  text-align: left;
  padding: 16px;
  margin-bottom: 8px;
  border-radius: 12px;
  border: none;
  background-color: transparent;
  color: #f3f4f6;
  transition: all 0.2s ease;
}

.program-editor__sidebar__list button:hover {
  background-color: #111827;
}

.program-editor__sidebar__list button.selected {
  background-color: #6366f1;
}

.program-editor__sidebar__list button.selected p {
  color: rgba(243, 244, 246, 0.8);
}

.program-editor__sidebar__list button h3 {
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 4px;
}

.program-editor__sidebar__list button p {
  font-size: 14px;
  color: #9ca3af;
}

.program-editor__content {
  background-color: #1f2937;
  border: 1px solid #374151;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  flex: 1;
  padding: 20px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.program-editor__content__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.program-editor__content__header__breadcrumb {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #9ca3af;
  font-size: 16px;
}
.program-editor__content__header__breadcrumb span {
  opacity: 0.8;
}
.program-editor__content__header__breadcrumb .chevron {
  opacity: 0.6;
}

.program-editor__content__header__actions button {
  background-color: #6366f1;
  color: #f3f4f6;
  border: none;
  border-radius: 8px;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.2s ease, transform 0.1s ease;
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 0.25rem;
}
.program-editor__content__header__actions button:hover {
  background-color: #4f46e5;
}
.program-editor__content__header__actions button:active {
  transform: scale(0.98);
}

.program-editor__content__programs {
  height: 100%;
  width: 100%;
}
.program-editor__content__programs .delete-program-button {
  position: absolute;
  top: 12px;
  right: 12px;
  padding: 8px 12px;
  background-color: transparent;
  border: 1px solid #FF5252;
  color: #FF5252;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s ease;
  z-index: 1;
}
.program-editor__content__programs .delete-program-button:hover {
  background-color: #FF5252;
  color: white;
}

.program-card {
  background-color: #111827;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid rgba(243, 244, 246, 0.1);
  transition: all 0.2s ease;
  margin-bottom: 1rem;
  position: relative;
}
.program-card h3 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 12px;
  color: #f3f4f6;
}
.program-card:hover {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.program-card h3 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 12px;
  color: #f3f4f6;
  opacity: 0.9;
}

.workout-list {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.workout-button {
  width: 100%;
  text-align: left;
  padding: 12px;
  background-color: #1f2937;
  border: 1px solid #374151;
  border-radius: 8px;
  color: #9ca3af;
  transition: all 0.2s ease;
  cursor: pointer;
}
.workout-button:hover {
  background-color: #374151;
  color: #f3f4f6;
}

.workout-item {
  width: 100%;
  text-align: left;
  padding: 12px;
  background-color: #111827;
  border: none;
  border-radius: 8px;
  color: #f3f4f6;
  transition: background-color 0.2s ease;
}

.workout-item:hover {
  background-color: #374151;
}

.program-editor__content__back-button {
  display: flex;
  align-items: center;
  gap: 4px;
  color: #9ca3af;
  padding: 4px 0;
  margin-bottom: 0.5rem;
  border: none;
  background: none;
  cursor: pointer;
  transition: color 0.2s ease;
  font-size: 14px;
}
.program-editor__content__back-button:hover {
  color: #f3f4f6;
}

.program-view {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}
.program-view .program-view__exercises {
  flex: 1;
  overflow-y: auto;
  padding: 0 12px;
  margin: 0 -12px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.program-view__title {
  font-size: 20px;
  font-weight: 600;
  color: #f3f4f6;
  margin: 12px 0;
}

.program-view__workout {
  width: 100%;
  text-align: left;
  padding: 16px;
  background-color: #1f2937;
  border: 1px solid rgba(243, 244, 246, 0.1);
  border-radius: 8px;
  color: #f3f4f6;
  transition: all 0.2s ease;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.program-view__workout:hover {
  background-color: #374151;
  transform: translateY(-1px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.program-view__workout:active {
  transform: translateY(0);
}

.view-workouts-button {
  width: 100%;
  text-align: center;
  padding: 12px;
  background-color: #374151;
  border: none;
  border-radius: 8px;
  color: #f3f4f6;
  transition: all 0.2s ease;
  cursor: pointer;
}
.view-workouts-button:hover {
  background-color: #6366f1;
  color: white;
}

.program-view__workouts {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.program-view__workout {
  width: 100%;
  text-align: left;
  padding: 16px;
  background-color: #374151;
  border: 1px solid rgba(243, 244, 246, 0.1);
  border-radius: 8px;
  color: #f3f4f6;
  transition: all 0.2s ease;
  cursor: pointer;
  margin-bottom: 12px;
}
.program-view__workout h3 {
  font-size: 16px;
  font-weight: 500;
  opacity: 0.9;
}
.program-view__workout:hover {
  background-color: #414d60;
  border-color: #6366f1;
}
.program-view__workout:active {
  transform: translateY(0);
}

.program-view__exercises {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.exercise-item {
  background-color: #1f2937;
  border: 1px solid #374151;
  border-radius: 12px;
  padding: 16px;
  margin-bottom: 12px;
  transition: all 0.2s ease;
}
.exercise-item:hover {
  border-color: #6366f1;
}
.exercise-item__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  position: relative;
}
.exercise-item__header__content {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.exercise-item__header__content h4 {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  color: white;
}
.exercise-item__header__content .superset-info {
  margin: 0;
  font-size: 16px;
  color: rgba(255, 255, 255, 0.6);
}
.exercise-item__header h4 {
  font-size: 18px;
  font-weight: 600;
  color: white;
  margin: 0;
}
.exercise-item__header button {
  color: #FF5252 !important;
  background: none;
  border: none;
  cursor: pointer;
  padding: 4px;
  border-radius: 4px;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  position: absolute;
  right: -12px;
  top: -4px;
}
.exercise-item__header button:hover {
  background-color: rgba(255, 82, 82, 0.1);
}
.exercise-item__controls {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 12px;
  margin-bottom: 12px;
}
.exercise-item__controls .control-group label {
  display: block;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.6);
  margin-bottom: 4px;
  text-transform: capitalize;
}
.exercise-item__controls .control-group .control-input {
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  background-color: rgba(13, 17, 23, 0.8);
  border-radius: 8px;
  padding: 2px;
  background-color: #111827;
  border: 1px solid #374151;
}
.exercise-item__controls .control-group .control-input button {
  padding: 4px 8px;
  color: white;
  border: none;
  background-color: #2a3545;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 36px;
  height: 36px;
  border-radius: 4px;
  margin: 2px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}
.exercise-item__controls .control-group .control-input button:hover {
  background-color: #343f52;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}
.exercise-item__controls .control-group .control-input button:active {
  transform: translateY(0);
  background-color: #2a3545;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}
.exercise-item__controls .control-group .control-input button:last-child {
  background-color: #6366f1;
}
.exercise-item__controls .control-group .control-input button:last-child:hover {
  background-color: #4f46e5;
}
.exercise-item__controls .control-group .control-input span {
  text-align: center;
  padding: 4px 8px;
  color: white;
  font-size: 16px;
  font-weight: 500;
  min-width: 48px;
}
.exercise-item__superset {
  margin-top: 12px;
}
.exercise-item__superset button {
  width: 100%;
  padding: 12px;
  background-color: #2a3545;
  border: none;
  border-radius: 8px;
  color: white;
  font-size: 16px;
  transition: all 0.2s ease;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}
.exercise-item__superset button:hover {
  background-color: #343f52;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}
.exercise-item__superset button:active {
  transform: translateY(0);
  background-color: #2a3545;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}
.exercise-item__superset button.active {
  background-color: #6366f1;
  box-shadow: 0 2px 4px rgba(113, 97, 239, 0.3);
}
.exercise-item__superset button.active:hover {
  background-color: #4f46e5;
}
.exercise-item.supersetted {
  border: 1px solid #6366f1;
  position: relative;
  border-color: #6366f1;
  background-color: #283547;
}
.exercise-item.supersetted:not(:last-child) {
  margin-bottom: 20px;
}
.exercise-item.supersetted:after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: -20px;
  transform: translateX(-50%);
  width: 2px;
  height: 16px;
  background-color: #6366f1;
  z-index: 1;
}
.exercise-item.supersetted:last-child:after {
  display: none;
}
.exercise-item.supersetted .superset-info {
  color: #6366f1;
  opacity: 0.8;
}

.exercise-item__header button {
  color: rgba(255, 255, 255, 0.6);
  background: none;
  border: none;
  cursor: pointer;
  padding: 4px;
  border-radius: 4px;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
}
.exercise-item__header button:hover {
  color: white;
  background-color: rgba(255, 255, 255, 0.1);
}

.view-workouts-button {
  width: 100%;
  text-align: center;
  padding: 12px;
  background-color: #1f2937;
  border: 1px solid #374151;
  border-radius: 8px;
  color: #9ca3af;
  transition: all 0.2s ease;
  cursor: pointer;
  margin-top: 12px;
}
.view-workouts-button:hover {
  background-color: #374151;
  color: #f3f4f6;
  border-color: #6366f1;
}

.program-card {
  background-color: #111827;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid rgba(243, 244, 246, 0.1);
  transition: all 0.2s ease;
  margin-bottom: 1rem;
}
.program-card h3 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 12px;
  color: #f3f4f6;
}
.program-card:hover {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.workouts-header {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 20px;
  padding: 12px 0;
  border-bottom: 1px solid rgba(243, 244, 246, 0.1);
}
.workouts-header h2 {
  font-size: 20px;
  font-weight: 600;
  color: #f3f4f6;
  margin: 0;
}

.back-to-programs-button {
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 0.5rem 0rem;
  background: none;
  border: none;
  color: #9ca3af;
  cursor: pointer;
  transition: all 0.2s ease;
  font-size: 14px;
}
.back-to-programs-button:hover {
  color: #f3f4f6;
}

.workouts-grid {
  display: flex;
  flex-direction: column;
  gap: 12px;
  max-width: 800px;
  margin: 0 auto;
}

.exercise-item__superset button {
  background-color: #2a3545;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}
.exercise-item__superset button:hover {
  background-color: #343f52;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}
.exercise-item__superset button:active {
  transform: translateY(0);
  background-color: #2a3545;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}
.exercise-item__superset button.active {
  background-color: #7161EF;
  box-shadow: 0 2px 4px rgba(113, 97, 239, 0.3);
}

.training-container .container {
  background-color: #171717;
  display: flex;
  flex-direction: column;
}
.training-container .exerciseHeading {
  font-size: 20px;
  margin-bottom: 10px;
  margin-left: 0;
}
.training-container .itemContainer {
  border: 1px solid white;
  margin-bottom: 20px;
  padding: 16px;
  border-width: 1px;
  border-color: #ddd;
  border-radius: 8px;
  background-color: #171717;
}
.training-container .itemText {
  font-size: 16px;
  font-weight: bold;
  flex: 1;
  margin-left: 10px;
  background-color: #2E2E2E;
}
.training-container .inputContainer {
  padding-top: 2px;
  padding-bottom: 2px;
  display: flex;
  align-items: center;
  background-color: #2E2E2E;
  margin-bottom: 5px;
}
.training-container .input {
  width: 40px;
  height: 40px;
  border-color: #FFFFFF;
  border-width: 1px;
  margin-right: 8px;
  text-align: center;
  background-color: #373737;
  color: #FFFFFF;
}
.training-container .buttonContainer {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
}
.training-container .button {
  background-color: #007bff;
  border-radius: 4px;
  padding: 8px;
  width: 35px;
  margin-left: 2px;
  color: white;
  font-size: 16px;
  text-align: center;
  border: none;
  cursor: pointer;
}
.training-container .finishButton {
  background-color: #007bff;
  border-radius: 4px;
  padding: 10px;
  margin-top: 20px;
  margin-bottom: 40px;
  color: white;
  font-size: 16px;
  text-align: center;
  border: none;
  cursor: pointer;
}

.signup form {
  margin: 0 auto;
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  max-width: 400px;
}
.signup form div {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.clients-container .user {
  border: 1px solid #ccc;
  margin-bottom: 2rem;
  max-width: 350px;
  padding: 0.5rem;
}
.clients-container .clients-container__button-container {
  margin: 0.25rem;
}

.addExercisesToWorkout-container {
  display: flex;
  flex-direction: row;
}
.addExercisesToWorkout-container h1 {
  margin-bottom: 1.5rem;
}
.addExercisesToWorkout-container .addExercisesToWorkout-container__exercises {
  flex: 1;
  margin-right: 1rem;
}
.addExercisesToWorkout-container .addExercisesToWorkout-container__left {
  flex: 1;
}
.addExercisesToWorkout-container .container {
  background-color: #171717;
  display: flex;
  flex-direction: column;
}
.addExercisesToWorkout-container .exerciseHeading {
  font-size: 20px;
  margin-bottom: 10px;
  margin-left: 0;
}
.addExercisesToWorkout-container .itemContainer {
  border: 1px solid white;
  margin-bottom: 20px;
  padding: 16px;
  border-width: 1px;
  border-color: #ddd;
  border-radius: 8px;
  background-color: #171717;
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
}
.addExercisesToWorkout-container .itemContainer.superset {
  border: 2px solid blue;
}
.addExercisesToWorkout-container .itemContainer.selecting {
  border: 2px dashed green;
}
.addExercisesToWorkout-container .itemContainer .itemContainer__button {
  width: 25px;
  height: 23px;
  background-color: #f81f1f;
  position: absolute;
  top: 9px;
  right: 9px;
  border: none;
  cursor: pointer;
}
.addExercisesToWorkout-container .itemText {
  font-size: 16px;
  font-weight: bold;
  flex: 1;
  margin-left: 10px;
  background-color: #2E2E2E;
}
.addExercisesToWorkout-container .inputContainer {
  padding-top: 2px;
  padding-bottom: 2px;
  display: flex;
  align-items: center;
  background-color: #2E2E2E;
  margin-bottom: 5px;
}
.addExercisesToWorkout-container .input {
  width: 40px;
  height: 40px;
  border-color: #FFFFFF;
  border-width: 1px;
  margin-right: 8px;
  text-align: center;
  background-color: #373737;
  color: #FFFFFF;
}
.addExercisesToWorkout-container .buttonContainer {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
}
.addExercisesToWorkout-container .button {
  background-color: #007bff;
  border-radius: 4px;
  padding: 8px;
  width: 35px;
  margin-left: 2px;
  color: white;
  font-size: 16px;
  text-align: center;
  border: none;
  cursor: pointer;
}
.addExercisesToWorkout-container .supersetButton {
  margin-top: 10px;
  background-color: #007bff;
  border-radius: 4px;
  padding: 8px;
  color: white;
  font-size: 16px;
  text-align: center;
  border: none;
  cursor: pointer;
}
.addExercisesToWorkout-container .supersetButton.active {
  background-color: blue;
  color: white;
}
.addExercisesToWorkout-container .supersetButton.selecting {
  background-color: green;
  color: white;
}
.addExercisesToWorkout-container .supersetButton.pairing {
  background-color: orange;
  color: white;
}
.addExercisesToWorkout-container .add-workout-container__select-container {
  display: flex;
  flex: 1;
  flex-direction: column;
}
.addExercisesToWorkout-container .add-workout-container__select-container .add-workout-container__select-container__user,
.addExercisesToWorkout-container .add-workout-container__select-container .add-workout-container__select-container__program,
.addExercisesToWorkout-container .add-workout-container__select-container .add-workout-container__select-container__workout {
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
}
.addExercisesToWorkout-container .add-workout-container__select-container select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #2E2E2E;
  flex: 1;
}
.addExercisesToWorkout-container .add-workout-container__input-container {
  max-width: 400px;
}
.addExercisesToWorkout-container .add-workout-container__input-container form {
  display: flex;
  flex-direction: column;
}
.addExercisesToWorkout-container .add-workout-container__input-container input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #2E2E2E;
  flex: 1;
}
.addExercisesToWorkout-container .add-workout-container__input-container button {
  margin-top: 1rem;
  background-color: #007bff;
  border-radius: 4px;
  padding: 8px;
  margin-left: 2px;
  color: white;
  font-size: 16px;
  text-align: center;
  border: none;
  cursor: pointer;
}

.sidebar {
  width: 250px;
  background-color: #171717;
  padding: 20px;
  border-right: 1px solid #D1D5DB;
}
.sidebar * {
  background-color: #171717;
}
.sidebar li, .sidebar ul {
  list-style: none;
}
.sidebar ul {
  padding: 0;
}
.sidebar h3 {
  margin-top: 1.25rem;
  margin-bottom: 0.5rem;
}

.reset-password-form {
  width: 300px;
  margin: 50px auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #171717;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  color: #FFFFFF;
}

.reset-password-form h2 {
  text-align: center;
  margin-bottom: 20px;
}

.reset-password-form form {
  display: flex;
  flex-direction: column;
}

.reset-password-form label {
  margin-bottom: 8px;
  font-weight: bold;
}

.reset-password-form input {
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  background-color: #2E2E2E;
  width: 100%;
}

.reset-password-form button {
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
}

.reset-password-form button:hover {
  background-color: #0056b3;
}

.reset-password-form .loginForm__usernameContainer {
  display: flex;
  flex-direction: column;
}
.reset-password-form .loginForm__passwordContainer {
  display: flex;
  flex-direction: column;
}
.reset-password-form p {
  margin-top: 1rem;
  color: red;
}

.program-creation {
  padding: 16px;
  max-width: 800px;
  margin: 0 auto;
}
.program-creation h1 {
  font-size: 24px;
  font-weight: 700;
  color: #f3f4f6;
  margin-bottom: 20px;
}
.program-creation .program-details {
  background-color: #1f2937;
  border: 1px solid #374151;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  padding: 20px;
  margin-bottom: 20px;
}
.program-creation .program-details h2 {
  font-size: 20px;
  font-weight: 600;
  color: #f3f4f6;
  margin-bottom: 16px;
}
.program-creation .program-details__form-group {
  margin-bottom: 16px;
}
.program-creation .program-details__form-group label {
  display: block;
  font-size: 14px;
  color: #9ca3af;
  margin-bottom: 4px;
}
.program-creation .program-details__form-group input, .program-creation .program-details__form-group select {
  background-color: #1f2937;
  border: 1px solid #374151;
  border-radius: 8px;
  color: #f3f4f6;
  padding: 12px;
  font-size: 16px;
  width: 100%;
  transition: border-color 0.2s ease;
}
.program-creation .program-details__form-group input:focus, .program-creation .program-details__form-group select:focus {
  outline: none;
  border-color: #6366f1;
}
.program-creation .program-details .goal-selector {
  display: flex;
  gap: 12px;
  margin-top: 4px;
}
.program-creation .program-details .goal-selector .goal-button {
  flex: 1;
  background-color: #374151;
  color: #9ca3af;
  border: 1px solid #374151;
  border-radius: 8px;
  padding: 12px;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.2s ease;
}
.program-creation .program-details .goal-selector .goal-button:hover {
  background-color: #414d60;
}
.program-creation .program-details .goal-selector .goal-button.active {
  background-color: #6366f1;
  color: #f3f4f6;
  border-color: #6366f1;
}
.program-creation .program-details .goal-selector .goal-button.active:hover {
  background-color: #4f46e5;
}
.program-creation .program-details .date-inputs {
  display: flex;
  gap: 16px;
}
.program-creation .program-details .date-inputs .date-field {
  flex: 1;
}
.program-creation .loading-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 16rem;
}
.program-creation .loading-container .spinner {
  color: #6366f1;
  animation: spin 1s linear infinite;
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.program-creation .workouts {
  background-color: #1f2937;
  border: 1px solid #374151;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  padding: 20px;
}
.program-creation .workouts__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}
.program-creation .workouts__header h2 {
  font-size: 20px;
  font-weight: 600;
  color: #f3f4f6;
}
.program-creation .workouts__add-button {
  background-color: #6366f1;
  color: #f3f4f6;
  border: none;
  border-radius: 8px;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.2s ease, transform 0.1s ease;
  padding: 8px 12px;
  font-size: 14px;
}
.program-creation .workouts__add-button:hover {
  background-color: #4f46e5;
}
.program-creation .workouts__add-button:active {
  transform: scale(0.98);
}
.program-creation .workouts__list {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.program-creation .workouts__empty {
  text-align: center;
  padding: 20px 0;
  color: #9ca3af;
}
.program-creation .workouts__empty button {
  background-color: #6366f1;
  color: #f3f4f6;
  border: none;
  border-radius: 8px;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.2s ease, transform 0.1s ease;
  margin-top: 12px;
}
.program-creation .workouts__empty button:hover {
  background-color: #4f46e5;
}
.program-creation .workouts__empty button:active {
  transform: scale(0.98);
}
.program-creation .workout-item {
  background-color: #374151;
  border-radius: 8px;
  padding: 12px;
  display: flex;
  gap: 12px;
  align-items: center;
}
.program-creation .workout-item__input {
  background-color: #1f2937;
  border: 1px solid #374151;
  border-radius: 8px;
  color: #f3f4f6;
  padding: 12px;
  font-size: 16px;
  width: 100%;
  transition: border-color 0.2s ease;
  margin: 0;
  flex: 1;
}
.program-creation .workout-item__input:focus {
  outline: none;
  border-color: #6366f1;
}
.program-creation .workout-item__remove {
  background: none;
  border: none;
  color: #FF5252;
  cursor: pointer;
  padding: 4px;
  border-radius: 4px;
  transition: background-color 0.2s ease;
}
.program-creation .workout-item__remove:hover {
  background-color: rgba(255, 82, 82, 0.1);
}
.program-creation .submit-button {
  background-color: #6366f1;
  color: #f3f4f6;
  border: none;
  border-radius: 8px;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.2s ease, transform 0.1s ease;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}
.program-creation .submit-button:hover {
  background-color: #4f46e5;
}
.program-creation .submit-button:active {
  transform: scale(0.98);
}
.program-creation .error-message {
  background-color: rgba(255, 82, 82, 0.1);
  color: #FF5252;
  padding: 12px;
  border-radius: 8px;
  margin-top: 12px;
  text-align: center;
}
.program-creation .program-details__form-group label {
  margin-top: 12px;
  font-size: 16px;
  color: #f3f4f6;
}

.edit-exercises {
  padding: 16px;
  height: 100%;
}
.edit-exercises h2 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 20px;
  color: #f3f4f6;
}
.edit-exercises__container {
  display: flex;
  gap: 20px;
  height: calc(100vh - 200px);
}
.edit-exercises__list {
  flex: 1;
  background-color: #1f2937;
  border: 1px solid #374151;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  padding: 20px;
  overflow-y: auto;
}
.edit-exercises__form {
  flex: 1;
  background-color: #1f2937;
  border: 1px solid #374151;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  padding: 20px;
}

.exercise-groups {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.exercise-group__title {
  font-size: 18px;
  font-weight: 500;
  color: #f3f4f6;
  margin-bottom: 12px;
}
.exercise-group__items {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.exercise-item {
  padding: 12px;
  border-radius: 8px;
  background-color: #374151;
  cursor: pointer;
  transition: all 0.2s ease;
}
.exercise-item--selected {
  background-color: #6366f1;
}

.form-group {
  margin-bottom: 16px;
}
.form-group label {
  display: block;
  margin-bottom: 8px;
  color: #9ca3af;
  font-weight: 500;
}
.form-group select {
  background-color: #1f2937;
  border: 1px solid #374151;
  border-radius: 8px;
  color: #f3f4f6;
  padding: 12px;
  font-size: 16px;
  width: 100%;
  transition: border-color 0.2s ease;
}
.form-group select:focus {
  outline: none;
  border-color: #6366f1;
}

.form-error {
  color: #FF5252;
  margin-bottom: 12px;
  font-size: 14px;
}

button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}
button:disabled:hover {
  background-color: #6366f1;
}

.modal-overlay {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.5);
  animation: overlayShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
}
.modal-content {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90vw;
  max-width: 500px;
  background-color: #1f2937;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  animation: contentShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
  border: 1px solid #374151;
}
.modal-title {
  font-size: 24px;
  font-weight: 700;
  color: #f3f4f6;
  margin-bottom: 12px;
}
.modal-description {
  color: #9ca3af;
  font-size: 16px;
  margin-bottom: 20px;
}
.modal-footer {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
}
.modal-button {
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s ease;
  font-weight: 500;
  font-size: 16px;
}
.modal-button--cancel {
  background-color: #374151;
  color: #f3f4f6;
  border: 1px solid transparent;
}
.modal-button--cancel:hover {
  background-color: #414d60;
}
.modal-button--delete {
  background-color: transparent;
  border: 1px solid #FF5252;
  color: #FF5252;
}
.modal-button--delete:hover {
  background-color: #FF5252;
  color: #f3f4f6;
}

@keyframes overlayShow {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes contentShow {
  from {
    opacity: 0;
    transform: translate(-50%, -48%) scale(0.96);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}
.spinner {
  display: inline-block;
  position: relative;
}
.spinner--small {
  width: 16px;
  height: 16px;
}
.spinner--medium {
  width: 48px;
  height: 48px;
}
.spinner--large {
  width: 64px;
  height: 64px;
}
.spinner::after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #6366f1;
  animation: spin 0.8s linear infinite;
}
.spinner--secondary::after {
  border-top-color: #9ca3af;
}

.spinner-container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 120px);
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

