.addExercisesToWorkout-container {
  display: flex;
  flex-direction: row;

  h1 {
      margin-bottom: 1.5rem;
  }

  .addExercisesToWorkout-container__exercises {
      flex: 1;
      margin-right: 1rem;
  }

  .addExercisesToWorkout-container__left {
      flex: 1;
  }

  .container {
      background-color: $primary-bg;
      display: flex;
      flex-direction: column;
  }
    
  .exerciseHeading {
      font-size: 20px;
      margin-bottom: 10px;
      margin-left: 0;
  }
    
  .itemContainer {
      border: 1px solid white;
      margin-bottom: 20px;
      padding: 16px;
      border-width: 1px;
      border-color: #ddd;
      border-radius: 8px;
      background-color: $primary-bg;
      display: flex;
      flex-direction: column;
      flex: 1;
      position: relative;

      &.superset {
          border: 2px solid blue;
      }

      &.selecting {
          border: 2px dashed green;
      }

      .itemContainer__button {
          width: 25px;
          height: 23px;
          background-color: $error;
          position: absolute;
          top: 9px;
          right: 9px;
          border: none;
          cursor: pointer;
      }
  }
    
  .itemText {
      font-size: 16px;
      font-weight: bold;
      flex: 1;
      margin-left: 10px;
      background-color: $secondary-bg;
  }
    
  .inputContainer {
      padding-top: 2px;
      padding-bottom: 2px;
      display: flex;
      align-items: center;
      background-color: $secondary-bg;
      margin-bottom: 5px;
  }
    
  .input {
      width: 40px;
      height: 40px;
      border-color: $primary-text;
      border-width: 1px;
      margin-right: 8px;
      text-align: center;
      background-color: $input-bg;
      color: $primary-text;
  }
    
  .buttonContainer {
      display: flex;
      justify-content: space-between;
      margin: 0 auto;
  }
    
  .button {
      background-color: #007bff;
      border-radius: 4px;
      padding: 8px;
      width: 35px;
      margin-left: 2px;
      color: white;
      font-size: 16px;
      text-align: center;
      border: none;
      cursor: pointer;
  }

  .supersetButton {
      margin-top: 10px;
      background-color: #007bff;
      border-radius: 4px;
      padding: 8px;
      color: white;
      font-size: 16px;
      text-align: center;
      border: none;
      cursor: pointer;

      &.active {
          background-color: blue;
          color: white;
      }

      &.selecting {
          background-color: green;
          color: white;
      }

      &.pairing {
          background-color: orange;
          color: white;
      }
  }

  .add-workout-container__select-container {
      display: flex;
      flex: 1;
      flex-direction: column;

      .add-workout-container__select-container__user,
      .add-workout-container__select-container__program,
      .add-workout-container__select-container__workout {
          display: flex;
          flex-direction: column;
          margin-bottom: 2rem;
      }

      select {
          width: 100%;
          padding: 10px;
          border: 1px solid #ccc;
          border-radius: 4px;
          background-color: $secondary-bg;
          flex: 1;
      }
  }

  .add-workout-container__input-container {
      max-width: 400px;
  
      form {
          display: flex;
          flex-direction: column;
      }
  
      input {
          width: 100%;
          padding: 10px;
          border: 1px solid #ccc;
          border-radius: 4px;
          background-color: $secondary-bg;
          flex: 1;
      }
  
      button {
          margin-top: 1rem;
          background-color: #007bff;
          border-radius: 4px;
          padding: 8px;
          margin-left: 2px;
          color: white;
          font-size: 16px;
          text-align: center;
          border: none;
          cursor: pointer;
      }
  }
}