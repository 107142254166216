@import 'variables';

.program-creation {
  padding: map-get($spacing, lg);
  max-width: 800px;
  margin: 0 auto;

  h1 {
    font-size: map-get(map-get($typography, sizes), xxl);
    font-weight: map-get(map-get($typography, weights), bold);
    color: map-get(map-get($colors, text), primary);
    margin-bottom: map-get($spacing, xl);
  }

  // Program Details Section
  .program-details {
    @include card;
    padding: map-get($spacing, xl);
    margin-bottom: map-get($spacing, xl);

    h2 {
      font-size: map-get(map-get($typography, sizes), xl);
      font-weight: map-get(map-get($typography, weights), semibold);
      color: map-get(map-get($colors, text), primary);
      margin-bottom: map-get($spacing, lg);
    }

    &__form-group {
      margin-bottom: map-get($spacing, lg);

      label {
        display: block;
        font-size: map-get(map-get($typography, sizes), sm);
        color: map-get(map-get($colors, text), secondary);
        margin-bottom: map-get($spacing, xs);
      }

      input, select {
        @include input-field;
      }
    }

    .goal-selector {
      display: flex;
      gap: map-get($spacing, md);
      margin-top: map-get($spacing, xs);
  
      .goal-button {
        flex: 1;
        background-color: map-get(map-get($colors, background), tertiary);
        color: map-get(map-get($colors, text), secondary);
        border: 1px solid map-get(map-get($colors, background), tertiary);
        border-radius: map-get($border-radius, md);
        padding: map-get($spacing, md);
        font-size: map-get(map-get($typography, sizes), md);
        cursor: pointer;
        transition: all 0.2s ease;
  
        &:hover {
          background-color: lighten(map-get(map-get($colors, background), tertiary), 5%);
        }
  
        &.active {
          background-color: map-get(map-get($colors, accent), primary);
          color: map-get(map-get($colors, text), primary);
          border-color: map-get(map-get($colors, accent), primary);
  
          &:hover {
            background-color: map-get(map-get($colors, accent), primary-hover);
          }
        }
      }
    }
  
    .date-inputs {
      display: flex;
      gap: map-get($spacing, lg);
  
      .date-field {
        flex: 1;
      }
    }
  }
  
  // Add this if you don't have loading styles yet
  .loading-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 16rem;
  
    .spinner {
      color: map-get(map-get($colors, accent), primary);
      animation: spin 1s linear infinite;
    }
  }
  
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  // Workouts Section
  .workouts {
    @include card;
    padding: map-get($spacing, xl);

    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: map-get($spacing, lg);

      h2 {
        font-size: map-get(map-get($typography, sizes), xl);
        font-weight: map-get(map-get($typography, weights), semibold);
        color: map-get(map-get($colors, text), primary);
      }
    }

    &__add-button {
      @include button-primary;
      padding: map-get($spacing, sm) map-get($spacing, md);
      font-size: map-get(map-get($typography, sizes), sm);
    }

    &__list {
      display: flex;
      flex-direction: column;
      gap: map-get($spacing, md);
    }

    &__empty {
      text-align: center;
      padding: map-get($spacing, xl) 0;
      color: map-get(map-get($colors, text), secondary);

      button {
        @include button-primary;
        margin-top: map-get($spacing, md);
      }
    }
  }

  // Workout Item
  .workout-item {
    background-color: map-get(map-get($colors, background), tertiary);
    border-radius: map-get($border-radius, md);
    padding: map-get($spacing, md);
    display: flex;
    gap: map-get($spacing, md);
    align-items: center;

    &__input {
      @include input-field;
      margin: 0;
      flex: 1;
    }

    &__remove {
      background: none;
      border: none;
      color: map-get(map-get($colors, status), error);
      cursor: pointer;
      padding: map-get($spacing, xs);
      border-radius: map-get($border-radius, sm);
      transition: background-color 0.2s ease;

      &:hover {
        background-color: rgba(map-get(map-get($colors, status), error), 0.1);
      }
    }
  }

  // Submit Button
  .submit-button {
    @include button-primary;
    width: 100%;
    margin-top: map-get($spacing, xl);
    margin-bottom: map-get($spacing, xxl);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: map-get($spacing, sm);
  }

  // Error Message
  .error-message {
    background-color: rgba(map-get(map-get($colors, status), error), 0.1);
    color: map-get(map-get($colors, status), error);
    padding: map-get($spacing, md);
    border-radius: map-get($border-radius, md);
    margin-top: map-get($spacing, md);
    text-align: center;
  }


  .program-details__form-group {
    label {
      margin-top: map-get($spacing, md);
      font-size: map-get(map-get($typography, sizes), md);
      color: map-get(map-get($colors, text), primary);
    }
  }
}